import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

function hexToBytes(hex)
{
  if( hex.substr(0,1) === '#' )
  {
    hex = hex.substring(1);
  }

  for (var bytes = [], c = 0; c < hex.length; c += 2)
  {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }

  return { r: bytes[0], b: bytes[2], g: bytes[1], a: 1 };
}

class ColorPickerComponent extends React.Component {
  state = {
    viewPicker: false,
    color: {
        rgb:{
            r: 255,
            b: 0,
            g: 0,
            a: 1, 
        },
        hex:'#21228f',
        hsl:{
            a: 1,
            h: 239,
            l: 0,
            s: 5,
        }
    },
  };


  rmsTestHex( inputColor )
  {
    var myRgb = hexToBytes(inputColor);
    this.setState(
    {
      color:
      {
        rgb:myRgb,
        hex:inputColor,
        hsl:{ a: 1, h: 0, l: 0, s: 0,}
      }
    }
    );

  }

  handleOnClick = () => {
    this.setState({
        viewPicker: !this.state.viewPicker,
    });
  };

  handleOnClose = () => {
    this.setState({
      viewPicker: false,
    });
  };

  handleOnChange = (color) => {
    this.setState({
      color: {
        rgb:color.rgb,
        hex:color.hex,
        hsl:color.hsl
    },
    });
    // Passing the selected color to parent component
    setTimeout(() => {
      // SetTimeout added to update color correctly
      this.props.onColorSelect(this.state.color);
    });

  };

  render() {


    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.rgb.r}, ${this.state.color.rgb.g}, ${this.state.color.rgb.b}, ${this.state.color.rgb.a})`,
        },
        swatch: {
          padding: '5px',
          background: "white",
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: "4",
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleOnClick}>
          <div style={styles.color} />
        </div>
        {this.state.viewPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleOnClose} />
            <SketchPicker
              color={this.state.color.rgb}
              onChange={this.handleOnChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPickerComponent;



