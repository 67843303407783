import React, { useRef, useState } from 'react';
import './App.css';
import * as tf from "@tensorflow/tfjs";
import * as facemesh from "@tensorflow-models/facemesh";
import Webcam from "react-webcam";
import { drawMesh } from "./utilities";
import styled from 'styled-components';
import ColorPickerComponent from "./colorpicker";
import recorder from 'react-canvas-recorder';


const theme = {
  blue: {
    default: "#3f51b5",
    hover: "#283593"
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457"
  }
};

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

Button.defaultProps = {
  theme: "blue"
};


const ButtonToggle = styled(Button)`
  opacity: 0.7;
  ${({ active }) =>
    active &&
    `
    opacity: 1; 
  `}
`;

function App()
{
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const dotPicker = useRef(null);
  const linePicker = useRef(null);
  const bckgPicker = useRef(null);

  var m_bWantRecord = false;
  var m_bRecording = false;

  function startRecording()
  {
    m_bWantRecord = true;
  }

  function internalStartRecording()
  {
    if( null !== canvasRef.current && false === m_bRecording )
    {
//      console.log("Starting recording");

      recorder.createStream(canvasRef.current);
      recorder.start();
      ToggleRecordButtons( true );
      m_bRecording = true;
    }
  }

  function ToggleRecordButtons( bRecording )
  {
    if( true === bRecording )
    {
        document.getElementById( "recordButton" ).style.visibility = "hidden";      
        document.getElementById( "stopButton" ).style.visibility = "visible";
    }
    else
    {
      document.getElementById( "recordButton" ).style.visibility = "visible";
      document.getElementById( "stopButton" ).style.visibility = "hidden";
    }
  }

  function stopRecording()
  {
    m_bWantRecord = false;
  }

  function internalStopRecording()
  {
    if( true  === m_bRecording )
    {
      recorder.stop();
      const file = recorder.save();

      // Gather chunks of video data into a blob and create an object URL
      const recording_url = URL.createObjectURL(file);
  
      // Attach the object URL to an <a> element, setting the download file name
      const a = document.createElement('a');
      a.style = "display: none;";
      a.href = recording_url;
      a.download = "video.webm";
      document.body.appendChild(a);
  
      // Trigger the file download
      a.click();
      setTimeout(() => {
        // Clean up - see https://stackoverflow.com/a/48968694 for why it is in a timeout
        URL.revokeObjectURL(recording_url);
        document.body.removeChild(a);
      }, 0);

      ToggleRecordButtons( false );
      m_bRecording = false;
    }
  }
  
  var firstTime = true;
  var myWidth = 640;
  var myHeight = 480;

  var m_DotColor = '#ff0000';
  var m_LineColor = '#C0C0C0';
  var m_BckgColor = '#000000';

  var m_bShowVideo = true;
  var m_bShowDots = true;
  var m_bShowLines = false;

  function onClickToggleVideo()
  {
    m_bShowVideo = !m_bShowVideo;
  
    if( typeof webcamRef.current !== "undefined" && webcamRef.current !== null )
    {
      if( false === m_bShowVideo )
      {
        document.getElementById( "webCamID" ).style.visibility = "hidden";      
      }
      else
      {
        document.getElementById( "webCamID" ).style.visibility = "visible";      
      }
    }
  }

  function onClickDots()
  {
    m_bShowDots = true;
    m_bShowLines = false;
  }
  function onClickLines()
  {
    m_bShowDots = false;
    m_bShowLines = true;
  }
  function onClickDotsAndLines()
  {
    m_bShowDots = true;
    m_bShowLines = true;
  }

  function onChangeDotColor( color )
  {
//    console.log("onChangeDotColor");
    m_DotColor = color.hex;
  }

  function onChangeLineColor( color )
  {
    m_LineColor = color.hex;
  }

  function onChangeBckgColor( color )
  {
    m_BckgColor = color.hex;
  }

  function handleContextLost(event)
  {
//    console.log("handleContextLost");
  }
  
  function handleContextRestored(event)
  {
//    console.log("handleContextRestored");
  }  

  // Load facemesh
  const runFacemesh = async() =>
  {
    const net = await facemesh.load();
    setInterval(()=>{detect(net) }, 10 )
  }

  var m_bInDetect = false;

  const detect = async(net) =>
  {
//    console.log("d 1");
    if( true === m_bInDetect )
    {
//      console.log("d 2");
      return;
    }

    m_bInDetect = true;

//    console.log("d 3");

    if( true === firstTime )
    {
//      console.log("d 4");

      if( typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4 )
      {
//        console.log("d 5");
  
        // Get video properties
        const video = webcamRef.current.video;
        myWidth = video.videoWidth;
        myHeight = video.videoHeight;
  
        // Set video width
        webcamRef.current.video.width = myWidth;
        webcamRef.current.video.height = myHeight;
  
        // Set canvas width
        canvasRef.current.width = myWidth;
        canvasRef.current.height = myHeight;

        canvasRef.current.addEventListener( "webglcontextlost", handleContextLost, false);
        canvasRef.current.addEventListener( "webglcontextrestored", handleContextRestored, false);

        ToggleRecordButtons(false);

        MyDebug();

        firstTime = false;
      }
    }
    else
    {
//      console.log("d 6");

        const face = await net.estimateFaces(webcamRef.current.video);

//        console.log("face = " + face);

        const ctx = canvasRef.current.getContext("2d");

//        console.log("ctx = " + ctx);

        if( false === m_bShowVideo )
        {
//          console.log("fill rect");

          ctx.fillStyle = m_BckgColor;
          ctx.fillRect( 0, 0, myWidth, myHeight );
        }
        else
        {
//          console.log("clear rect");

          ctx.clearRect( 0, 0, myWidth, myHeight );
        }

//        console.log("d 7");

        drawMesh( face, ctx, m_bShowDots, m_bShowLines, m_DotColor, m_LineColor );

//        console.log("d 8");
      }

//      console.log("d 9");

    if( true === m_bWantRecord && false === m_bRecording )
    {
//      console.log("d A");
      internalStartRecording();
    }

//    console.log("d B");

    if( true === m_bRecording && false === m_bWantRecord )
    {
//      console.log("d C");
      internalStopRecording();
    }

    m_bInDetect = false;
//    console.log("d D");

  }

  function MyDebug()
  {
    // Output the current colour of the dot picker to the console
    if( typeof dotPicker.current !== "undefined" && dotPicker.current !== null )
    {
      dotPicker.current.rmsTestHex( m_DotColor );
    }

    if( typeof linePicker.current !== "undefined" && linePicker.current !== null )
    {
      linePicker.current.rmsTestHex( m_LineColor );
    }

    if( typeof bckgPicker.current !== "undefined" && bckgPicker.current !== null )
    {
      bckgPicker.current.rmsTestHex( m_BckgColor );
    }
  }


  runFacemesh();

  return (
    <div className="App">
    <header className="App-header">
    <Webcam ref={webcamRef} id="webCamID" style={
    {
      position:"absolute",
      marginLeft:"0",
      marginRight:"0",
      left:0,
      right:0,
      textAlign:"center",
      zIndex:9,
      width:640,
      height:480
    }
    } />
    <canvas ref={canvasRef} style={
    {
      position:"absolute",
      marginLeft:"0",
      marginRight:"0",
      left:0,
      right:0,
      textAlign:"center",
      zIndex:9,
      width:640,
      height:480
    }
    } />
    <div style={
    {
    position:"absolute",
    marginLeft:"auto",
    marginRight:"auto",
    left:650,
    right:0,
    textAlign:"left",
    }}>
    <div><Button onClick={onClickToggleVideo}>Toggle Video</Button></div>
      <div>
      <ButtonToggle theme="pink" active={true} onClick={onClickDots}>Dots</ButtonToggle>
      <ButtonToggle theme="pink" active={true} onClick={onClickLines}>Lines</ButtonToggle>
      <ButtonToggle theme="pink" active={true} onClick={onClickDotsAndLines}>Dots &amp; Lines</ButtonToggle>
      </div>
      <div style={{fontSize:"14px"}}>
        Dot Colour: <ColorPickerComponent ref={dotPicker} onColorSelect={onChangeDotColor} />
        Line Colour: <ColorPickerComponent ref={linePicker} onColorSelect={onChangeLineColor} />
        Background Colour: <ColorPickerComponent ref={bckgPicker} onColorSelect={onChangeBckgColor} />
      </div>
      <div><Button id="recordButton" onClick={startRecording}>Record</Button>
      <Button id="stopButton" onClick={stopRecording}>Stop</Button></div>
      </div>

      </header>
    </div>
  );
}

export default App;
